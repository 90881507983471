import React from "react"

import HeadWrapper, { IHeadWrapper } from "components/atoms/HeadWrapper"
import CookieMessage from "components/atoms/CookieMessage"
import Footer from "components/molecules/Footer"
import Nav from "components/organisms/Nav"

interface IPageWrapper extends IHeadWrapper {
  fullScreen?: boolean
}

const PageWrapper = ({
  children,
  title,
  titlePosition = "start",
  description,
  fullScreen = false,
}: IPageWrapper) => {
  return (
    <HeadWrapper
      title={title}
      titlePosition={titlePosition}
      description={description}
    >
      {!fullScreen && <Nav />}
      {children}
      {!fullScreen && <Footer />}
      <CookieMessage />
    </HeadWrapper>
  )
}

export default PageWrapper
