import React from "react"
import styled, { css } from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface ILine {
  active: boolean
}

interface IHamburger extends ILine {
  className?: string
  onClick: () => void
}

const SHamburger = styled.button`
  align-items: center;
  display: flex;
  user-select: none;
`

const SHamburgerInner = styled.div`
  align-items: stretch;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: ${rems(16)};
  justify-content: space-between;
  padding: 0;
  width: ${rems(18)};
`

const SLine = styled.div<ILine>`
  background-color: currentColor;
  border-radius: 2px;
  height: 2px;
  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.color.primary};

      &:first-child {
        transform: rotate(45deg);
        transform-origin: 0 ${rems(5)};
      }

      &:nth-child(2) {
        transform: translateX(-100%);
        opacity: 0;
      }

      &:last-child {
        transform: rotate(-45deg);
        transform-origin: ${rems(1)} ${rems(-2)};
      }
    `};
  transition: all 0.5s ease;
`

const Hamburger = ({ active, className, onClick }: IHamburger) => (
  <SHamburger onClick={onClick} className={className}>
    <SHamburgerInner>
      <SLine active={active} />
      <SLine active={active} />
      <SLine active={active} />
    </SHamburgerInner>
  </SHamburger>
)

export default Hamburger
