import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

import { rems } from "base_css/utils/fontSize"
import { breakpoint } from "base_css/vars"
import { CustomLink } from "components/atoms/CustomLink"
import { Container } from "components/atoms/Grid"
import Hamburger from "components/atoms/Hamburger"
import GetStartedButton from "components/molecules/GetStartedButton"
import Logo from "components/svg/Logo"

import { useSelectorNavColor } from "features/slices/navColorSlice"

export const SMALL_SCREEN_NAV_HEIGHT = rems(65)
export const LARGE_SCREEN_NAV_HEIGHT = rems(100)

interface IMain {
  isOpen: boolean
}

interface IBackdrop {
  isOpen: boolean
}

interface INav {
  isTop: boolean
  navColor: string | undefined
}

const SNav = styled.nav<INav>`
  background-color: ${({ navColor }) => navColor && navColor};
  color: ${({ theme, navColor }) =>
    navColor ? theme.color.white : theme.color.primary};
  display: flex;
  height: ${LARGE_SCREEN_NAV_HEIGHT};
  justify-content: stretch;
  position: fixed;
  transition: all 0.2s ease;
  z-index: ${({ theme }) => theme.zIndex.towerBlock};

  /* stylelint-disable */
  ${({ isTop, theme }) =>
    !isTop &&
    css`
      box-shadow: 0 0 ${rems(10)} 0 rgba(34, 34, 34, 0.15);
      background-color: ${theme.color.white};
      color: ${theme.color.primary};
    `};
  /* stylelint-enable */

  /* Add space before the first section after this so its bg sits behind the transparent nav */
  & + * {
    &::before {
      content: "";
      display: block;
      height: ${SMALL_SCREEN_NAV_HEIGHT};
    }
  }

  ${breakpoint.mediumDown} {
    height: ${SMALL_SCREEN_NAV_HEIGHT};
    left: 0;
    right: 0;
    top: 0;
  }

  ${breakpoint.large} {
    left: 0;
    right: 0;
    top: 0;

    /* Add space before the first section after this so its bg sits behind the transparent nav */
    & + * {
      &::before {
        height: ${LARGE_SCREEN_NAV_HEIGHT};
      }
    }
  }

  /* Remove browser focus outline */
  * {
    &:focus {
      outline: none;
    }

    &:-moz-focusring {
      outline: none;
    }
  }
`

const SContainer = styled(Container)`
  display: flex;

  ${breakpoint.large} {
    padding-left: ${({ theme }) => theme.spacing.medium};
    padding-right: ${({ theme }) => theme.spacing.medium};
  }
`

const SLink = styled(CustomLink)`
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  min-width: ${rems(100)};
`

const SMain = styled.div<IMain>`
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;

  ${breakpoint.mediumDown} {
    align-items: stretch;
    background-color: ${({ theme }) => theme.color.white};
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 80%;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: all 0.5s ease;
    width: ${rems(310)};
    z-index: ${({ theme }) => theme.zIndex.towerBlock};

    /* stylelint-disable */
    ${({ isOpen }) =>
      isOpen
        ? css`
            box-shadow: 0 0 ${rems(30)} 0 rgba(34, 34, 34, 0.3);
            transform: translateX(0%);
          `
        : ""};
    /* stylelint-enable */
  }
`

const SLinks = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${breakpoint.mediumDown} {
    overflow-y: scroll;
  }

  ${breakpoint.large} {
    flex-direction: row;
    order: -1;
    padding-left: ${({ theme }) => theme.spacing.small};
    padding-right: ${({ theme }) => theme.spacing.small};
  }
`

const SLinkItem = styled.li`
  display: flex;

  ${breakpoint.mediumDown} {
    border-bottom: 1px solid ${({ theme }) => theme.color.grey.lightest};
    flex-direction: column;
  }
`

const activeLink = css`
  background-color: ${({ theme }) => theme.color.accent};
  color: ${({ theme }) => theme.color.white};
`

const navItems = css`
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
  padding-left: ${({ theme }) => theme.spacing.medium};
  padding-right: ${({ theme }) => theme.spacing.medium};

  &:hover {
    color: ${({ theme }) => theme.color.accent};
  }

  ${breakpoint.mediumDown} {
    flex: 1;
    padding-bottom: ${({ theme }) => theme.spacing.medium};
    padding-left: ${({ theme }) => theme.spacing.mediumLarge};
    padding-top: ${({ theme }) => theme.spacing.medium};
    text-align: left;

    &:hover {
      ${activeLink}
    }
  }

  ${breakpoint.large} {
    align-items: center;
    color: inherit;
    display: flex;
  }
`

const SMainLink = styled(CustomLink)`
  ${navItems}
`

const SHamburger = styled(Hamburger)`
  color: inherit;
  margin-right: -${({ theme }) => theme.spacing.medium};
  padding-left: ${({ theme }) => theme.spacing.medium};
  padding-right: ${({ theme }) => theme.spacing.medium};
  z-index: ${({ theme }) => theme.zIndex.skyscraper};

  ${breakpoint.large} {
    display: none;
  }
`

const SCta = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  text-align: left;

  ${breakpoint.mediumDown} {
    align-items: baseline;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey.lightest};
    box-sizing: content-box;
    flex-direction: column-reverse;
    padding-bottom: ${({ theme }) => theme.spacing.medium};
    padding-left: ${({ theme }) => theme.spacing.medium};
    padding-top: ${({ theme }) => theme.spacing.medium};
  }
`

const SGetStartedButton = styled(GetStartedButton)`
  ${breakpoint.mediumDown} {
    margin-bottom: ${({ theme }) => theme.spacing.mediumSmall};
  }
`

const SBackdrop = styled.div<IBackdrop>`
  background-color: black;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.5s ease;
  visibility: hidden;
  z-index: ${({ theme }) => theme.zIndex.house};

  /* stylelint-disable */
  ${({ isOpen }) =>
    isOpen
      ? css`
          opacity: 0.7;
          visibility: visible;
        `
      : ""};
  /* stylelint-enable */
`

const Nav = () => {
  const navColor = useSelectorNavColor()
  const [isOpen, setOpen] = useState(false)
  const [isTop, setTop] = useState(true)

  useEffect(() => {
    handleScroll()
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setTop(false)
    } else {
      setTop(true)
    }
  }

  const closeSideBar = () => setOpen(false)

  return (
    <SNav isTop={isTop} navColor={navColor}>
      <SContainer>
        <SLink href="/" analyticsID="Nav Logo button">
          <Logo />
        </SLink>
        <SMain isOpen={isOpen}>
          <SCta>
            <SGetStartedButton analyticsID="Nav GetStartedButton" />
          </SCta>
          <SLinks>
            <SLinkItem>
              <SMainLink
                onClick={closeSideBar}
                href="/first-time-buyer/"
                analyticsID="Nav /first-time-buyer"
              >
                First-time buyer
              </SMainLink>
            </SLinkItem>
            <SLinkItem>
              <SMainLink
                onClick={closeSideBar}
                href="/remortgage/"
                analyticsID="Nav /remortgage"
              >
                Remortgage
              </SMainLink>
            </SLinkItem>
            <SLinkItem>
              <SMainLink
                onClick={closeSideBar}
                href="/buying-home/"
                analyticsID="Nav /buying-home"
              >
                Buying a home
              </SMainLink>
            </SLinkItem>
            <SLinkItem>
              <SMainLink
                onClick={closeSideBar}
                href="/buy-to-let/"
                analyticsID="Nav /buy-to-let"
              >
                Buy to let
              </SMainLink>
            </SLinkItem>
            <SLinkItem>
              <SMainLink
                onClick={closeSideBar}
                href="/calculators/"
                analyticsID="Nav /calculators"
              >
                Calculators
              </SMainLink>
            </SLinkItem>
          </SLinks>
        </SMain>
        <SHamburger active={isOpen} onClick={() => setOpen(!isOpen)} />
        <SBackdrop isOpen={isOpen} onClick={() => setOpen(false)} />
      </SContainer>
    </SNav>
  )
}

export default Nav
