export const hexToRgba = (hex: string, alpha = 1) => {
  const hexArray = hex.match(/\w\w/g)

  if (hexArray && hexArray.length === 3) {
    const [r, g, b] = hexArray.map((x: string) => parseInt(x, 16))
    return `rgba(${r},${g},${b},${alpha})`
  } else {
    throw new Error("Incorrect hex format given")
  }
}
