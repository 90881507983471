import config from "config"
import Head from "next/head"
import MetaImage from "public/images/metaImage.png"
import React from "react"

export interface IHeadWrapper {
  children: React.ReactNode
  title?: string
  titlePosition?: "start" | "end"
  description?: string
}

const metaTitle = (title: string | undefined, position: string) =>
  !title
    ? "NOVA Mortgages"
    : position === "start"
    ? `${title} | NOVA Mortgages`
    : `NOVA Mortgages | ${title}`

export const defaultMetaDescription =
  "Whether you’re a first-time buyer or already own a house, there are lots of decisions to make during the buying process. We’re here to help you every step of the way."

const HeadWrapper = ({
  children,
  title,
  titlePosition = "start",
  description,
}: IHeadWrapper) => {
  return (
    <>
      <Head>
        <title>{metaTitle(title, titlePosition)}</title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@novawm_uk" />
        {
          <meta
            name="description"
            content={description ?? defaultMetaDescription}
          />
        }
        {
          <meta
            property="og:description"
            content={description ?? defaultMetaDescription}
          />
        }
        <meta
          property="og:title"
          content={metaTitle(title, titlePosition)}
          key="og:title"
        />
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:image" content={MetaImage.src} key="og:image" />
        <meta
          property="og:url"
          content="https://mortgages.novawm.com/"
          key="og:url"
        />
        <meta httpEquiv="Content-Language" content="en" />
        <meta name="locale" content="en_GB" />
        <meta name="site_name" content="NOVA Wealth Mortgages" />
        <link rel="icon" href="/icons/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        {config.ENV === "production" && (
          <script
            type="text/javascript"
            src="/pardotWebsiteTrackingScript.js"
            defer
          ></script>
        )}
      </Head>

      {children}
    </>
  )
}

export default HeadWrapper
