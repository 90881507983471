import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"
import { breakpoint } from "base_css/vars"
import { useTrustpilotRating } from "features/api/useRequest"
import { hexToRgba } from "base_css/utils/color"
import TrustpilotStar from "components/svg/TrustpilotStar"
import { UnstyledInlineExternalLink } from "components/atoms/CustomLink"

interface ITrustpilotStarContainer {
  color?: string
  fill: number
}

const TrustpilotStarContainer = styled.div<ITrustpilotStarContainer>`
  align-items: center;
  background: ${({ color, theme, fill }) =>
    `linear-gradient(to right, ${color ?? theme.color.primary} ${
      fill * 100
    }%, ${hexToRgba(color ?? theme.color.primary, 0.5)} ${fill * 100}%)`};
  display: flex;
  flex-direction: column;
  height: ${rems(22)};
  justify-content: center;
  width: ${rems(22)};
`

const STrustpilot = styled.div`
  display: flex;

  & > * {
    margin-left: ${rems(2)};
  }
`

const STrustpilotText = styled.span<Pick<ITrustpilotStarContainer, "color">>`
  color: ${({ color, theme }) => color ?? theme.color.primary};
  display: inline;
  vertical-align: baseline;
`

const STrustpilotRating = styled.div`
  align-items: center;
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing.small};

  ${breakpoint.small} {
    flex-direction: column;
  }
`

const STrustpilotEmphasis = styled.b`
  font-size: ${({ theme }) => theme.fontSize.body};
`

function starFillFraction(rating: number, starNum: number) {
  if (rating >= starNum) {
    return 1
  }
  return rating - (starNum - 1)
}

interface ITrustpilotRating {
  className?: string
  boxColor?: string
  starColor?: string
}

const TrustpilotRating = ({
  className,
  boxColor,
  starColor,
}: ITrustpilotRating) => {
  const { stars, trustScore } = useTrustpilotRating()

  const getRatingText = () => {
    if (!trustScore) {
      return "Excellent"
    }
    if (trustScore > 4.2) {
      return "Excellent"
    } else if (trustScore > 3.7) {
      return "Great"
    } else if (trustScore > 2.7) {
      return "Average"
    } else if (trustScore > 1.7) {
      return "Poor"
    } else {
      return "Bad"
    }
  }
  return (
    <UnstyledInlineExternalLink
      className={className}
      href="https://uk.trustpilot.com/review/novawm.com"
      target="_blank"
      analyticsID="Trustpilot button"
    >
      <STrustpilotRating>
        <STrustpilotText color={boxColor}>
          Rated {getRatingText()} on{" "}
          <STrustpilotEmphasis>Trustpilot</STrustpilotEmphasis>
        </STrustpilotText>
        <STrustpilot>
          {Array.from({ length: 5 }, (x, i) => (
            <TrustpilotStarContainer
              key={i}
              fill={starFillFraction(stars, i + 1)}
              color={boxColor}
            >
              <TrustpilotStar fill={starColor} />
            </TrustpilotStarContainer>
          ))}
        </STrustpilot>
      </STrustpilotRating>
    </UnstyledInlineExternalLink>
  )
}

export default TrustpilotRating
