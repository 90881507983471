import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface IFacebook {
  className?: string
  fill?: string
}

const SFacebook = styled.svg<IFacebook>`
  fill: ${({ fill, theme }) => (fill ? fill : theme.color.white)};
  margin-right: ${({ theme }) => theme.spacing.small};
  width: ${rems(25)};
`

const Facebook = ({ className, fill }: IFacebook) => (
  <SFacebook className={className} fill={fill} viewBox="0 0 30 30">
    <g
      id="Page-designs"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop---Resrouce---Content-page---PDF-v2"
        transform="translate(-1227.000000, -2651.000000)"
        fill="#FFFFFF"
      >
        <g id="Footer-/-Legal" transform="translate(-66.000000, 2449.000000)">
          <path
            d="M1308,202 C1299.716,202 1293,208.716 1293,217 C1293,224.163 1298.024,230.144 1304.739,231.634 L1304.739,220.707 L1300.706,220.707 L1300.706,216.115 L1304.739,216.115 L1304.739,212.615 C1304.739,208.634 1307.11,206.435 1310.738,206.435 C1312.477,206.435 1314.295,206.745 1314.295,206.745 L1314.295,210.654 L1312.291,210.654 C1310.318,210.654 1309.703,211.879 1309.703,213.135 L1309.703,216.115 L1314.108,216.115 L1313.404,220.707 L1309.703,220.707 L1309.703,231.807 C1309.036,231.912 1308.352,231.964 1307.66,231.982 C1307.774,231.985 1307.885,232 1308,232 C1316.284,232 1323,225.284 1323,217 C1323,208.716 1316.284,202 1308,202"
            id="Facebook-icon"
          />
        </g>
      </g>
    </g>
  </SFacebook>
)

export default Facebook
