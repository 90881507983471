import React from "react"
import styled from "styled-components"

interface ISpacing {
  spacing?: "xSmall" | "small" | "mediumSmall" | "medium" | "mediumLarge"
}

interface IMultiItemSpacer extends ISpacing {
  children: React.ReactNode
  className?: string
}

const SMultiItemSpacer = styled.div`
  overflow: hidden;
`

const SChildWrapper = styled.div<ISpacing>`
  --spacing: ${({ theme, spacing }) =>
    spacing ? theme.spacing[spacing] : theme.spacing.medium};

  margin-bottom: calc(var(--spacing) * -1);
  margin-right: calc(var(--spacing) * -1);

  & > * {
    margin-bottom: var(--spacing);
    margin-right: var(--spacing);
  }
`

const MultiItemSpacer = ({
  children,
  className,
  spacing,
}: IMultiItemSpacer) => (
  <SMultiItemSpacer>
    <SChildWrapper className={className} spacing={spacing}>
      {children}
    </SChildWrapper>
  </SMultiItemSpacer>
)

export default MultiItemSpacer
