import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface ILogo {
  className?: string
  fill?: string
}

const SLogo = styled.svg<ILogo>`
  fill: currentColor;
  height: ${rems(21)};
`

export const Logo = ({ className, fill }: ILogo) => (
  <SLogo
    className={className}
    viewBox="155 210 535 138"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fill={fill}
  >
    <path d="M359.9 217.1c-34.4 0-62.4 28-62.4 62.4s28 62.4 62.4 62.4 62.4-28 62.4-62.4-28-62.4-62.4-62.4zm0 108.7c-25.6 0-46.4-20.8-46.4-46.4 0-25.6 20.8-46.4 46.4-46.4 25.6 0 46.4 20.8 46.4 46.4-.1 25.6-20.9 46.4-46.4 46.4zM267.2 219v128.3l-98.4-98.4v91h-16V210.3l98.4 98.4V219h16zm357.9-8.3L560.8 340h17.9l11.3-22.7h70.1l11.2 22.7h17.9l-64.1-129.3zm-27.9 92 27.8-56 27.7 56h-55.5zM563.1 219l-62 128.6L439 219h17.8l44.3 91.8 44.3-91.8h17.7z" />
  </SLogo>
)

export default Logo
