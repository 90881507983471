import React, { useEffect, useState } from "react"
import styled, { createGlobalStyle, css } from "styled-components"

import { hexToRgba } from "base_css/utils/color"
import { rems } from "base_css/utils/fontSize"
import { breakpoint } from "base_css/vars"
import { ButtonStyled } from "components/atoms/Button"
import { InlineExternalLink } from "components/atoms/CustomLink"
import * as ga from "features/analytics/ga"

const COOKIE_NAME = "OW_COOKIE_CONSENT"

const SCookieWrapper = styled.div`
  align-items: center;
  backdrop-filter: blur(${rems(5)});
  background-color: ${({ theme }) => hexToRgba(theme.color.primary, 0.75)};
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.medium};
  position: fixed;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.skyscraper};

  ${breakpoint.mediumDown} {
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.small};
  }
`

const SButtonContainer = styled.div`
  display: flex;

  ${breakpoint.mediumDown} {
    justify-content: flex-end;
    width: 100%;
  }
`

const SCookieText = styled.p`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-bottom: 0;
  margin-right: ${({ theme }) => theme.spacing.medium};
  text-align: left;

  ${breakpoint.mediumDown} {
    margin-bottom: ${({ theme }) => theme.spacing.small};
    margin-right: 0;
    text-align: justify;
  }
`

const SButton = styled(ButtonStyled)`
  border: 2px solid transparent !important;
  border-radius: ${rems(100)};
  box-shadow: 0 ${rems(5)} ${rems(10)} 0 rgba(34, 34, 34, 0.15);
  cursor: pointer;
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: ${({ theme }) => theme.spacing.small}
    ${({ theme }) => theme.spacing.medium};
  text-decoration: none;
  white-space: nowrap;

  &:disabled {
    background-color: ${({ theme }) => theme.color.grey.dark};
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.white} !important;
    border: 2px solid ${({ theme }) => theme.color.accent} !important;
    color: ${({ theme }) => theme.color.accent} !important;
  }
`

const SRejectButton = styled(SButton)`
  margin-right: ${({ theme }) => theme.spacing.small};

  &:hover {
    background-color: transparent !important;
    border: 2px solid ${({ theme }) => theme.color.white} !important;
    color: ${({ theme }) => theme.color.white} !important;
  }
`

const createCookie = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const expiresDate = new Date(year + 1, month, day)
  const expires = `expires=${expiresDate}`
  document.cookie = `${COOKIE_NAME}=1; ${expires}`
}

const anonymiseData = () => {
  localStorage.setItem("anonymiseData", "true")
}

const cookieCssClass = "cookie-message-shown"

const checkCookieExists = () => {
  const cookieArray = document.cookie.split(";")
  for (const cookie of cookieArray) {
    const name = cookie.split("=")[0]
    if (name.trim() === COOKIE_NAME) {
      return true
    }
  }
  document.body.classList.add(cookieCssClass)
  return false
}

const checkCookieRejected = () => {
  return localStorage.getItem("anonymiseData") === "true"
}

interface IGlobalStyleProps {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  cookieHeight: any
}

const GlobalStyle = createGlobalStyle<IGlobalStyleProps>`
  .${cookieCssClass} .BeaconFabButtonFrame {
    ${({ cookieHeight }) =>
      cookieHeight &&
      css`
        transform: translateY(-${cookieHeight}px) scale(1) !important;
      `}
  }
`

const CookieMessage = () => {
  const [showMessage, setShowMessage] = useState(false)
  const [cookieHeight, setCookieHeight] = useState(0)
  const cookieWrapper = React.createRef<HTMLDivElement>()

  useEffect(() => {
    const hideMessage = checkCookieExists() || checkCookieRejected()
    if (!hideMessage) {
      setShowMessage(true)
    }
  }, [])

  useEffect(() => {
    if (cookieWrapper.current) {
      setCookieHeight(cookieWrapper.current.clientHeight)
    }
  }, [cookieWrapper])

  const cookiesAccepted = () => {
    createCookie()
    document.body.classList.remove(cookieCssClass)
    setShowMessage(false)
  }

  const cookiesRejected = () => {
    anonymiseData()
    setShowMessage(false)
    ga.anonymiseData()
  }

  if (!showMessage) {
    return <React.Fragment />
  } else {
    return (
      <>
        <GlobalStyle cookieHeight={cookieHeight} />
        <SCookieWrapper ref={cookieWrapper}>
          <SCookieText>
            We&apos;re using cookies on this site. Some of these are essential
            whilst others are helping us improve your experience and providing
            insights for us. See our
            <InlineExternalLink href="/privacy-policy/" target="_self">
              privacy policy
            </InlineExternalLink>
            for full details.
          </SCookieText>

          <SButtonContainer>
            <SRejectButton
              onClick={cookiesRejected}
              analyticsID="CookieMessage reject button"
              variant="secondary"
            >
              Reject
            </SRejectButton>
            <SButton
              onClick={cookiesAccepted}
              analyticsID="CookieMessage accept button"
              testId="cookie-accept"
              variant="pink"
            >
              Got it
            </SButton>
          </SButtonContainer>
        </SCookieWrapper>
      </>
    )
  }
}

export default CookieMessage
