import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface ILinkedin {
  className?: string
  fill?: string
}

const SLinkedin = styled.svg<ILinkedin>`
  fill: ${({ fill, theme }) => (fill ? fill : theme.color.white)};
  width: ${rems(25)};
`

const Linkedin = ({ className, fill }: ILinkedin) => (
  <SLinkedin className={className} fill={fill} viewBox="0 0 30 30">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop---Singlepage-scroll---v3"
        transform="translate(-1328.000000, -6112.000000)"
      >
        <g id="Footer-/-Legal" transform="translate(-66.000000, 5910.000000)">
          <g
            id="Social---linked-in"
            transform="translate(1394.000000, 202.000000)"
          >
            <path
              d="M23.5732,23.6719 L20.3442,23.6569 L20.3442,16.0929 C20.2982,15.8379 19.5562,14.0209 18.1822,13.9319 C16.6912,13.6339 15.2402,14.6009 14.9412,16.0929 L14.9412,23.6569 L11.6992,23.6569 L11.6992,10.6899 L14.9412,10.6899 L14.9412,12.8509 C16.0112,11.5509 17.5822,10.7649 19.2632,10.6899 C21.4932,10.5439 23.4192,12.2339 23.5642,14.4629 C23.5692,14.5399 23.5722,14.6169 23.5732,14.6939 L23.5732,23.6719 Z M7.9172,9.6089 C7.0222,9.6089 6.2962,8.8829 6.2962,7.9879 C6.2962,7.0929 7.0222,6.3669 7.9172,6.3669 C8.8122,6.3669 9.5382,7.0929 9.5382,7.9879 C9.5382,8.8829 8.8122,9.6089 7.9172,9.6089 L7.9172,9.6089 Z M6.2962,23.6569 L9.5382,23.6569 L9.5382,10.6889 L6.2962,10.6889 L6.2962,23.6569 Z M15.0002,-0.0001 C6.7162,-0.0001 0.0002,6.7159 0.0002,14.9999 C0.0002,23.2839 6.7162,29.9999 15.0002,29.9999 C23.2842,29.9999 30.0002,23.2839 30.0002,14.9999 C30.0002,6.7159 23.2842,-0.0001 15.0002,-0.0001 L15.0002,-0.0001 Z"
              id="Fill-1"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </SLinkedin>
)

export default Linkedin
