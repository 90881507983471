import React from "react"
import styled from "styled-components"

import { breakpoint } from "base_css/vars"
import { CustomLink, InlineExternalLink } from "components/atoms/CustomLink"
import { Cell, Container, Grid } from "components/atoms/Grid"
import MultiItemSpacer from "components/atoms/MultiItemSpacer"
import TrustpilotRating from "components/atoms/TrustpilotRating"
import Facebook from "components/svg/Facebook"
import Linkedin from "components/svg/Linkedin"
import Logo from "components/svg/Logo"

const SFooter = styled.footer`
  background-color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-top: auto;
  position: relative;
  text-align: left;
`

const SContainer = styled(Container)`
  padding: ${({ theme }) => theme.spacing.large};
`

const SPageLinks = styled.div`
  color: ${({ theme }) => theme.color.white};
`

const SLegalitiesCell = styled(Cell)`
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  margin-top: -1px; /* Handles clipping issues */
  & > *:last-child {
    margin-bottom: 0;
  }

  ${breakpoint.small} {
    text-align: center;
  }
`

const SFooterEnd = styled.div`
  background-color: ${({ theme }) => theme.color.grey.lightest};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
  padding-top: ${({ theme }) => theme.spacing.medium};
`

const SLogo = styled(Logo)`
  fill: currentColor;
`

const SLogoLink = styled(CustomLink)`
  align-items: center;
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.mediumLarge};

  ${breakpoint.small} {
    justify-content: center;
  }
`

const SList = styled.ul`
  align-items: center;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding-left: 0;

  ${breakpoint.small} {
    flex-direction: column;
    justify-content: center;
  }
`

const SMultiItemSpacer = styled(MultiItemSpacer)`
  display: flex;
  flex-wrap: wrap;

  ${breakpoint.small} {
    align-items: center;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing.xSmall};
  }
`

const SLink = styled(CustomLink)`
  color: inherit;
`

const SSocialMediaLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${breakpoint.small} {
    justify-content: center;
  }
`

const SGrid = styled(Grid)`
  ${breakpoint.small} {
    grid-gap: ${({ theme }) => theme.spacing.medium};
  }
`

const PageLinks = () => (
  <SPageLinks>
    <SList>
      <SMultiItemSpacer>
        <li>
          <SLink
            href="/first-time-buyer/"
            analyticsID="Footer /first-time-buyer"
          >
            First-time buyer
          </SLink>
        </li>
        <li>
          <SLink href="/remortgage/" analyticsID="Footer /remortgage">
            Remortgage
          </SLink>
        </li>
        <li>
          <SLink href="/buying-home/" analyticsID="Footer /buying-a-home">
            Buying a home
          </SLink>
        </li>
        <li>
          <SLink href="/buy-to-let/" analyticsID="Footer /buy-to-let">
            Buy to let
          </SLink>
        </li>
        <li>
          <SLink href="/calculators/" analyticsID="Footer /calculators">
            Calculators
          </SLink>
        </li>
      </SMultiItemSpacer>
    </SList>
  </SPageLinks>
)

const SocialMediaLinks = () => (
  <SSocialMediaLinks>
    <InlineExternalLink
      href="https://facebook.com/novawm.uk"
      analyticsID="Footer Facebook button"
    >
      <Facebook />
    </InlineExternalLink>
    <InlineExternalLink
      href="https://www.linkedin.com/company/nova-wealth-uk"
      analyticsID="Footer Linkedin button"
    >
      <Linkedin />
    </InlineExternalLink>
  </SSocialMediaLinks>
)

const Footer = () => (
  <SFooter>
    <SContainer>
      <SLogoLink href="/" analyticsID="Footer Logo button">
        <SLogo />
      </SLogoLink>
      <SGrid>
        <Cell area="1 / 1 / span 1 / span 12" mdArea="1 / 1 / span 1 / span 8">
          <PageLinks />
        </Cell>
        <Cell area="2 / 1 / span 1 / span 12" mdArea="3 / 9 / span 1 / span 4">
          <SocialMediaLinks />
        </Cell>
        <SLegalitiesCell
          area="4 / 1 / span 1 / span 12"
          mdArea="2 / 1 / span 2 / span 8"
        >
          <p>
            NOVA is a trading name of NOVA Wealth Limited, which is authorised
            and regulated by the Financial Conduct Authority (FRN: 778951) and
            is a limited company registered in England & Wales (10739796) at 20
            Farringdon Street, London, EC4A 4AB
          </p>
          <p>&copy; {new Date().getFullYear()} NOVA Wealth.</p>
        </SLegalitiesCell>
      </SGrid>
    </SContainer>
    <SFooterEnd>
      <Container>
        <SList>
          <SMultiItemSpacer>
            <li>
              <SLink href="/privacy-policy/">Privacy policy</SLink>
            </li>
            <li>
              <SLink href="/terms-of-use/">Terms of Use</SLink>
            </li>
          </SMultiItemSpacer>
          <TrustpilotRating />
        </SList>
      </Container>
    </SFooterEnd>
  </SFooter>
)

export default Footer
